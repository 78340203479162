import {
  Card,
  CardBody,
  Heading,
  Divider,
  CardHeader,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";

const LANGUAGE = {
  free: {
    header: "Rookie",
    price: "Free",
    items: [
      "Rate limit: 30 req/min",
      "Players Endpoint",
      "Teams Endpoint",
      "Games Endpoint (current season)",
    ],
  },
  paid: {
    header: "ALL-STAR",
    price: "$9.99 / mo",
    items: [
      "Rate limit: 600 req/min",
      "Everything in FREE",
      "Stats Endpoint (1946-current)",
      "Season Averages Endpoint (1946-current)",
      "Active Players Endpoint",
    ],
  },
  "paid+": {
    header: "GOAT",
    price: "$39.99 / mo",
    items: [
      "Rate limit: 6000 req/min",
      "Everything in ALL-STAR",
      "Advanced Stats Endpoint (1996-current)",
      "Box Scores Live Endpoint",
      "Box Scores Endpoint (1946-current)",
      "Team Standings Endpoint (1970-current)",
      "League Leaders Endpoint (1951-current)",
      "Live Betting Odds Endpoint",
    ],
  },
};

export const PricingCard = ({
  tier,
  highlight,
}: {
  tier: "free" | "paid" | "paid+";
  highlight?: boolean;
}) => {
  const lang = LANGUAGE[tier];
  const highlightProps = {
    borderColor: "teal",
    borderWidth: 3,
  };
  return (
    <Card maxW="md" {...(highlight ? highlightProps : {})}>
      <CardHeader>
        <Heading size="md">{lang.header}</Heading>
        <Heading size="xl">{lang.price}</Heading>
      </CardHeader>
      <Divider />
      <CardBody>
        <UnorderedList>
          {lang.items.map((item, idx) => (
            <ListItem key={idx} marginY={2}>
              {item}
            </ListItem>
          ))}
        </UnorderedList>
      </CardBody>
    </Card>
  );
};
